<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="card card--light">
          <div
            class="alert"
            role="alert"
            v-show="alert.on"
            :class="alert.type"
            id="alertRef"
          >
            {{ alert.text }}
          </div>

          <div class="card card--accent card__form mb-4" v-if="message.on">
            <div class="card__form-header-secodery mb-3">
              <div class="d-flex justify-content-center mb-3">
                <div class="text-center" :class="message.type">
                  {{ message.text }}
                </div>
              </div>
              <div class="" v-if="status == 3">
                <div class="d-flex justify-content-center">
                  <button class="btn btn-primary" @click="getSignatureFile"
                    >Télécharger</button
                  >
                </div>
              </div>
              <div class="" v-if="status == 2">
                <div class="d-flex justify-content-center">
                  <button
                    class="btn btn-primary"
                    @click="resendEmailSignature"
                    :disabled="resendIsLoading"
                    >Renvoyer l'email</button
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            class="card card--light d-flex justify-content-center alighn-items-center mb-4"
            v-if="showForm && formLoaded && !signatureBlocked"
          >
            <div class="d-block justify-content-center alighn-items-center">
              <div class="text-primary">
                Vous pouvez maintenant lancer la procédure de signature
              </div>
              <div class="d-flex justify-content-center pt-3 pb-3">
                <button
                  class="btn btn-primary-light"
                  @click.prevent="launchSignature"
                  >Lancer
                </button>
              </div>
            </div>
          </div>
          <Form
            @submit="onSubmit"
            v-slot="{ errors }"
            @invalid-submit="onInvalidSubmit"
            enctype="multipart/form-data"
          >
            <div v-if="tokenValid && showForm">
              <div class="card card--accent card__form" v-if="formLoaded">
                <div class="card__form-header">
                  <div
                    >Bonjour {{ fiche.trustedFirstname }}
                    {{ fiche.trustedLastname }}
                  </div>
                  <div>Fiche : {{ fiche.code }}</div>
                </div>
                <div class="card__form-body">
                  <div class="row mt-4">
                    <div class="col-lg-6">
                      <label class="label-dark label-lg" for=""
                        >Nom d’usage<span class="text-danger text-gras"
                          >*</span
                        ></label
                      >
                      <Field
                        v-model="fiche.trustedLastname"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors.trustedLastname }"
                        name="trustedLastname"
                        placeholder="Nom d’usage*"
                        rules="required|alphaSpaces"
                        v-maska="{
                          mask: 'Z*',
                          tokens: { Z: { pattern: /[a-zA-Z@ ÉÀÇéàçè*+!.,']/ } },
                        }"
                        @keydown.enter.stop.prevent
                      />
                      <ErrorMessage
                        as="div"
                        class="form-alert"
                        name="trustedLastname"
                      />
                    </div>
                    <div class="col-lg-6">
                      <label class="label-dark label-lg" for=""
                        >Prénom<span class="text-danger text-gras"
                          >*</span
                        ></label
                      >
                      <Field
                        v-model="fiche.trustedFirstname"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors.trustedFirstname }"
                        name="trustedFirstname"
                        placeholder="Prénom*"
                        rules="required|alphaSpaces"
                        v-maska="{
                          mask: 'Z*',
                          tokens: { Z: { pattern: /[a-zA-Z@ ÉÀÇéàçè*+!.,']/ } },
                        }"
                        @keydown.enter.stop.prevent
                      />
                      <ErrorMessage
                        as="div"
                        class="form-alert"
                        name="trustedFirstname"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <label class="label-dark label-lg" for=""
                        >Nom de naissance<span class="text-danger text-gras"
                          >*</span
                        ></label
                      >
                      <Field
                        v-model="fiche.trustedBirthName"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors.trustedBirthName }"
                        name="trustedBirthName"
                        placeholder="Nom de naissance*"
                        rules="required|alphaSpaces"
                        @keydown.enter.stop.prevent
                      />
                      <ErrorMessage
                        as="div"
                        class="form-alert"
                        name="trustedBirthName"
                      />
                    </div>
                    <div class="col-lg-6">
                      <label class="label-dark label-lg" for=""
                        >Date de naissance<span class="text-danger text-gras"
                          >*</span
                        ></label
                      >
                      <Field
                        v-model="fiche.birthday"
                        :class="{ 'is-invalid': errors.birthday }"
                        name="birthday"
                        rules="required|date"
                      >
                        <Datepicker
                          v-model="fiche.birthday"
                          :enableTimePicker="false"
                          :autoApply="true"
                          locale="fr"
                          format="dd/MM/yyyy"
                          :inputClassName="errors.birthday ? 'is-invalid' : ''"
                        />
                      </Field>
                      <ErrorMessage
                        as="div"
                        class="form-alert"
                        name="birthday"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <label class="label-dark label-lg" for=""
                        >Commune de naissance de l’assuré</label
                      >
                      <Field
                        v-model="fiche.trustedBirthPlace"
                        type="text"
                        class="form-control"
                        name="trustedBirthPlace"
                        placeholder="Commune de naissance"
                        rules="alphaSpaces"
                        v-maska="{
                          mask: 'Z*',
                          tokens: { Z: { pattern: /[a-zA-Z@ ÉÀÇéàçè*+!.,']/ } },
                        }"
                        @keydown.enter.stop.prevent
                      />
                      <ErrorMessage
                        as="div"
                        class="form-alert"
                        name="trustedBirthPlace"
                      />
                    </div>
                    <div class="col-lg-6">
                      <label class="label-dark label-lg" for=""
                        >Pays de naissance
                      </label>
                      <Field
                        v-model="fiche.birthCountry"
                        type="text"
                        class="form-control"
                        name="birthCountry"
                        placeholder="Pays de naissance"
                        rules="alphaSpaces"
                        v-maska="{
                          mask: 'Z*',
                          tokens: { Z: { pattern: /[a-zA-Z@ ÉÀÇéàçè*+!.,']/ } },
                        }"
                        @keydown.enter.stop.prevent
                      />
                      <ErrorMessage
                        as="div"
                        class="form-alert"
                        name="birthCountry"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <label class="label-dark label-lg" for=""
                        >Appartement, escalier, étage
                      </label>
                      <textarea
                        v-model="fiche.address1"
                        type="text"
                        class="form-control"
                        name="address1"
                        placeholder="Appartement, escalier, étage"
                        :rows="3"
                      />
                    </div>

                    <div class="col-lg-6">
                      <label class="label-dark label-lg" for=""
                        >Bâtiment, résidence
                      </label>
                      <textarea
                        v-model="fiche.address2"
                        type="text"
                        class="form-control"
                        name="address2"
                        placeholder="Bâtiment, résidence"
                        :rows="3"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <label class="label-dark label-lg" for=""
                        >Numéro et nom de la voie<span
                          class="text-danger text-gras"
                          >*</span
                        >
                      </label>
                      <Field
                        as="textarea"
                        v-model="fiche.address3"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors.address4 }"
                        name="address4"
                        placeholder="Numéro et nom de la voie*"
                        rules="required"
                        :rows="3"
                      />
                      <ErrorMessage
                        as="div"
                        class="form-alert"
                        name="address4"
                      />
                    </div>
                    <div class="col-lg-6">
                      <label class="label-dark label-lg" for=""
                        >Complément d'adresse
                      </label>
                      <textarea
                        v-model="fiche.address4"
                        type="text"
                        class="form-control"
                        name="address1"
                        placeholder="Complément d'adresse"
                        :rows="3"
                      />
                    </div>
                    <div class="col-lg-6">
                      <label class="label-dark label-lg" for=""
                        >Code postal<span class="text-danger text-gras">*</span>
                      </label>
                      <Field
                        v-model="fiche.address5"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors.address5 }"
                        name="address5"
                        placeholder="Code postal*"
                        rules="required|numeric"
                        v-maska="'#*'"
                        @keydown.enter.stop.prevent
                      />
                      <ErrorMessage
                        as="div"
                        class="form-alert"
                        name="address5"
                      />
                    </div>
                    <div class="col-lg-6">
                      <label class="label-dark label-lg" for=""
                        >Ville<span class="text-danger text-gras">*</span>
                      </label>
                      <Field
                        v-model.lazy="fiche.address6"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors.address6 }"
                        name="address6"
                        placeholder="Ville*"
                        rules="required|alphaSpaces"
                        v-maska="{
                          mask: 'Z*',
                          tokens: { Z: { pattern: /[a-zA-Z@ ÉÀÇéàçè*+!.,']/ } },
                        }"
                        @input.prevent.stop="getZipcode(fiche.address6)"
                        @keydown.enter.stop.prevent
                      />
                      <ErrorMessage
                        as="div"
                        class="form-alert"
                        name="address6"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <label class="label-dark label-lg" for="">Pays </label>
                      <Field
                        v-model="fiche.pays"
                        type="text"
                        class="form-control"
                        name="pays"
                        placeholder="Pays"
                        rules="alphaSpaces"
                        v-maska="{
                          mask: 'Z*',
                          tokens: { Z: { pattern: /[a-zA-Z@ ÉÀÇéàçè*+!.,']/ } },
                        }"
                        @keydown.enter.stop.prevent
                      />
                      <ErrorMessage as="div" class="form-alert" name="pays" />
                    </div>
                    <div class="col-lg-6">
                      <label class="label-dark label-lg" for=""
                        >Courriel<span class="text-danger text-gras">*</span>
                      </label>
                      <Field
                        v-model.trim="fiche.email"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors.email }"
                        name="email"
                        placeholder="Courriel*"
                        rules="required|email"
                        @keydown.space.stop.prevent
                        @keydown.enter.stop.prevent
                      />
                      <ErrorMessage as="div" class="form-alert" name="email" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <label class="label-dark label-lg" for=""
                        >Téléphone mobile<span class="text-danger text-gras"
                          >*</span
                        >
                      </label>
                      <Field
                        v-model="fiche.phone"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors.phone }"
                        name="phone"
                        placeholder="Téléphone mobile*"
                        rules="required|numeric"
                        @keydown.enter.stop.prevent
                      />
                      <!-- |digits:10 -->
                        <!-- v-maska="'0#########'" -->
                      <ErrorMessage as="div" class="form-alert" name="phone" />
                    </div>
                    <div class="col-lg-6">
                      <label class="label-dark label-lg" for=""
                        >Téléphone fixe
                      </label>
                      <Field
                        v-model="fiche.fixe"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors.fixe }"
                        name="fixe"
                        placeholder="Téléphone fixe"
                        rules="numeric"
                        @keydown.enter.stop.prevent
                        v-maska="'##########'"
                      />
                      <ErrorMessage as="div" class="form-alert" name="fixe" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <label class="label-dark label-lg" for=""
                        >Raison sociale employeur<span
                          class="text-danger text-gras"
                          >*</span
                        >
                      </label>
                      <Field
                        :value="fiche.socialReason1"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors.socialReason1 }"
                        name="socialReason1"
                        placeholder="Nom de l’employeur*"
                        disabled
                      />
                    </div>

                    <div class="col-lg-6">
                      <label class="label-dark label-lg" for=""
                        >Adresse employeur<span class="text-danger text-gras"
                          >*</span
                        >
                      </label>
                      <textarea
                        :value="fiche.companyAddress1"
                        type="text"
                        class="form-control"
                        name="fiche.companyAddress1"
                        placeholder="Adresse employeur*"
                        :rows="3"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <label class="label-dark label-lg" for=""
                        >Code postal employeur<span
                          class="text-danger text-gras"
                          >*</span
                        >
                      </label>
                      <input
                        :value="fiche.companyZipcode"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors.companyZipcode }"
                        name="companyZipcode"
                        placeholder="Code postal employeur*"
                        disabled
                      />
                    </div>
                    <div class="col-lg-6">
                      <label class="label-dark label-lg" for=""
                        >Ville employeur<span class="text-danger text-gras"
                          >*</span
                        >
                      </label>
                      <input
                        :value="fiche.companyCity"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors.companyCity }"
                        name="companyCity"
                        placeholder="Ville employeur*"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="card card--accent card__form" v-else>
                <div class="card__form-header d-block">
                  <div class="d-flex justify-content-center">
                    <div
                      class="spinner-border spinner-border-md text-secondary"
                      role="status"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="d-flex justify-content-end"
              v-if="showForm && formLoaded"
            >
              <div class="pt-3 pb-3">
                <button class="btn btn-primary" :disabled="submitBlocked"
                  >Valider</button
                >
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { computed, nextTick, onMounted } from 'vue'
import { reactive, ref } from '@vue/reactivity'
import { Form, Field, ErrorMessage, defineRule } from 'vee-validate'
import { maska } from 'maska'
import { required, digits, numeric } from '@vee-validate/rules'
import { configure } from 'vee-validate'
import moment from 'moment'
import 'moment/locale/fr'
import { localize } from '@vee-validate/i18n'
import Datepicker from 'vue3-date-time-picker'
import 'vue3-date-time-picker/dist/main.css'

defineRule('required', required)
defineRule('digits', digits)
defineRule('alphaSpaces', (value) => {
  if (!/^[a-zA-Z .ÉÀÇéàçè']*$/.test(value)) {
    return 'Ce champ doit être Alphabétique'
  }
  return true
})
defineRule('numeric', numeric)
defineRule('email', (value) => {
  // Check if email
  if (
    !/^[a-z0-9_-]+(?:\.[a-z0-9_-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z]{2,4}(?:[a-z-]*[a-z]{2,4})?$/.test(
      value
    )
  ) {
    return 'Veuillez vérifier votre email'
  } else return true
})
defineRule('date', (value) => {
  console.log('1', value)
  console.log('2', !moment(value).isBefore())
  if (!moment(value).isBefore()) {
    return 'Veuillez vérifier votre date de naissance'
  }
  return true
})
configure({
  generateMessage: localize('fr', {
    messages: {
      required: 'Ce champ est obligatoire',
      digits: 'Veuillez vérifier votre numéro de  Téléphone',
      numeric: 'Ce champ doit être numérique',
    },
  }),
})

export default {
  name: 'FormCampaign',
  components: {
    Form,
    Field,
    ErrorMessage,
    Datepicker,
  },
  directives: { maska },
  setup() {
    const route = useRoute()
    const store = useStore()

    const alertRef = ref(null)
    let formLoaded = ref(false)
    let resendIsLoading = ref(false)
    let fiche = reactive({})
    const token = ref(route.query.token)
    let tokenValid = ref(false)
    let submitBlocked = ref(false)
    let signatureBlocked = ref(true)
    let showForm = ref(false)
    const alert = reactive({
      type: false,
      on: false,
      text: '',
    })
    const message = reactive({
      type: false,
      on: false,
      text: '',
    })
    const textInputOptions = ref({
      format: 'dd.mm.yyyy',
    })
    onMounted(() => {
      store
        .dispatch('app/fetchFormInformations', token.value)
        .then(() => {
          fiche = Object.assign(fiche, store.state.app.fiche)
          tokenValid.value = true
          if (fiche.birthday == null) {
            fiche.birthday = ''
          } else {
            fiche.birthday = new Date(fiche.birthday)
          }
          if (fiche.phone == null) {
            fiche.phone = ''
          }
          if (fiche.fixe == null) {
            fiche.fixe = ''
          }
          formLoaded.value = true
          if (status.value == 1) {
            signatureBlocked.value = false
          }
          if (status.value != 2 && status.value != 3) {
            showForm.value = true
          } else if (status.value == 2) {
            message.on = true
            message.text =
              "Merci ! Un courriel vient d'être envoyé à l'adresse " +
              fiche.email +
              ' pour finaliser la procédure de signature.'
          } else if (status.value == 3) {
            message.on = true
            message.text =
              'Votre attestation a bien été signée. Vous pouvez la télécharger via le lien suivant :'
          }
        })
        .catch((error) => {
          console.log(error.response)
          let data = {
            token: token.value,
          }
          store.dispatch('app/reportWrongToken', data)
          formLoaded.value = true
          showForm.value = false
          message.on = true
          message.type = 'alert-darnger'
          message.text = `Votre accès ne semble pas valide.
            Un message a été envoyé à notre service support pour résoudre votre problème.
            Nous ne manquons pas de revenir rapidement vers vous.
            Merci de votre compréhension.`
        })
    })
    let status = computed(() => {
      return fiche.status
    })

    const resendEmailSignature = async () => {
      resendIsLoading.value = true
      let params = {
        ficheId: fiche.id,
      }
      store
        .dispatch('app/resendEmailSignature', params)
        .then(async () => {
          alert.on = true
          alert.type = 'alert-success'
          alert.text =
            'Email renvoyé veuiller vérifier votre boite pour passer à l’étape de signature '
          await nextTick()
          let element = document.querySelector(`#alertRef`)
          element.scrollIntoView({ behavior: 'smooth' })
       })
        .catch(async () => {
          alert.on = true
          alert.type = 'alert-danger'
          alert.text = 'une erreur s’est produite, veuillez réessayer plus tard'
          await nextTick()
          let element = document.querySelector(`#alertRef`)
          element.scrollIntoView({ behavior: 'smooth' })
        })
        .finally(() => {
          resendIsLoading.value = false
          setTimeout(() => {
            alert.on = false
            alert.type = ''
            alert.text = ''
          }, 5000)
        })
    }
    const getSignatureFile = () => {
      store
        .dispatch('app/getSignatureFile', token.value)
        .then((blob) => {
          var fileURL = window.URL.createObjectURL(blob)
          var fileLink = document.createElement('a')
          fileLink.href = fileURL
          fileLink.setAttribute('download', 'signature-' + token.value)
          document.body.appendChild(fileLink)
          fileLink.click()
          fileLink.remove()
        })
        .catch(() => {})
    }
    const getZipcode = (data) => {
      if (data == '') {
        fiche.address5 = ''
        return
      }
      let params = {
        q: data,
      }
      store
        .dispatch('app/getAdresse', params)
        .then((response) => {
          fiche.address5 = response.features[0].properties.citycode
        })
        .catch(() => {})
    }
    const onInvalidSubmit = async ({ values, errors, results }) => {
      alert.on = true
      alert.type = 'alert-danger'
      alert.text = 'Veuillez compléter les informations demandées'
      await nextTick()
      console.log(document.querySelector(`#alertRef`))
      let element = document.querySelector(`#alertRef`)
      element.scrollIntoView({ behavior: 'smooth' })
      setTimeout(() => {
        alert.on = false
        alert.type = ''
        alert.text = ''
      }, 5000)
      console.log('values ', values) // current form values
      console.log('errors ', errors) // a map of field names and their first error message
      console.log('results ', results) // a detailed map of field names and their validation results
    }
    const onSubmit = () => {
      alert.on = false
      submitBlocked.value = true
      let params = {
        id: fiche.id,
        code: fiche.code,
        user_id: fiche.userId,
        birthday: moment(fiche.birthday).format('YYYY-MM-DD'),
        email: fiche.email,
        comment: fiche.comment,
        raison_sociale_1: fiche.socialReason1,
        raison_sociale_2: fiche.socialReason2,
        adresse_entreprise_1: fiche.companyAddress1,
        adresse_entreprise_2: fiche.companyAddress2,
        code_postale_entreprise: fiche.companyZipcode,
        ville_entreprise: fiche.companyCity,
        prenom_fiabilise: fiche.trustedFirstname,
        nom_fiabilise: fiche.trustedLastname,
        commune_de_naissance: fiche.trustedBirthPlace,
        nom_de_naissance: fiche.trustedBirthName,
        adresse_1: fiche.address1,
        adresse_2: fiche.address2,
        adresse_3: fiche.address3,
        adresse_4: fiche.address4,
        adresse_5: fiche.address5,
        adresse_6: fiche.address6,
        pays: fiche.pays,
        pays_de_naissance: fiche.birthCountry,
        fichier: '',
        phone: fiche.phone,
        fixe: fiche.fixe,
      }
      store
        .dispatch('app/updateFiche', params)
        .then(async () => {
          submitBlocked.value = false
          alert.on = true
          alert.type = 'alert-success'
          alert.text =
            'Votre fiche a bien été mise à jour. Si les informations vous semblent correctes, il ne vous reste plus qu’à passer à l’étape de signature pour finaliser la procédure.'
          fiche = Object.assign(fiche, store.state.app.fiche)
          fiche.birthday = new Date(fiche.birthday)
          if (fiche.status == 1) {
            signatureBlocked.value = false
          }
          console.log(document.querySelector(`#alertRef`))
          await nextTick()
          let element = document.querySelector(`#alertRef`)
          element.scrollIntoView({ behavior: 'smooth' })
          setTimeout(() => {
            alert.on = false
            alert.type = ''
            alert.text = ''
          }, 5000)
        })
        .catch(async (error) => {
          console.log(error.response)
          submitBlocked.value = false
          if (error.response.status == 421) {
            alert.on = true
            alert.type = 'alert-danger'
            alert.text = 'Veuillez compléter les informations demandées'
          } else {
            alert.on = true
            alert.type = 'alert-danger'
            alert.text =
              'Une erreur s’est produite. Veuillez réessayer plus tard.'
          }
          await nextTick()
          let element = document.querySelector(`#alertRef`)
          element.scrollIntoView({ behavior: 'smooth' })
          setTimeout(() => {
            alert.on = false
            alert.type = ''
            alert.text = ''
          }, 5000)
        })
    }
    const launchSignature = () => {
      if (status.value != 1) {
        return
      }
      let params = {
        data: {
          ficheId: fiche.id,
          phone: fiche.phone,
          birthday: moment(fiche.birthday).format('YYYY-MM-DD'),
          email: fiche.email,
          raison_sociale_1: fiche.socialReason1,
          adresse_entreprise_1: fiche.companyAddress1,
          adresse_entreprise_2: fiche.companyAddress2,
          code_postale_entreprise: fiche.companyZipcode,
          ville_entreprise: fiche.companyCity,
          prenom_fiabilise: fiche.trustedFirstname,
          nom_fiabilise: fiche.trustedLastname,
          commune_de_naissance: fiche.trustedBirthPlace,
          pays_de_naissance: fiche.birthCountry,
          nom_de_naissance: fiche.trustedBirthName,
          adresse_1: fiche.address1,
          adresse_2: fiche.address2,
          adresse_3: fiche.address3,
          adresse_4: fiche.address4,
          adresse_5: fiche.address5,
          adresse_6: fiche.address6,
          pays: fiche.pays,
          fixe: fiche.fixe,
        },
        output: {
          output: 'base64',
        },
      }
      store
        .dispatch('app/launchSignature', params)
        .then(async () => {
          fiche = Object.assign(fiche, store.state.app.fiche)
          fiche.status = 2
          showForm.value = false
          message.on = true
          message.text =
            "Merci ! Un courriel vient d'être envoyé à l'adresse " +
            fiche.email +
            ' pour finaliser la procédure de signature.'
          await nextTick()
          let element = document.querySelector(`#alertRef`)
          element.scrollIntoView({ behavior: 'smooth' })
        })
        .catch(async (err) => {
          console.log(err)
          console.log(err.message)
          signatureBlocked.value = false
          alert.on = true
          alert.type = 'alert-danger'
          alert.text =
            'Une erreur s’est produite. Veuillez réessayer plus tard.'
          await nextTick()
          let element = document.querySelector(`#alertRef`)
          element.scrollIntoView({ behavior: 'smooth' })
          setTimeout(() => {
            alert.on = false
            alert.type = ''
            alert.text = ''
          }, 5000)
        })
    }
    return {
      token,
      fiche,
      alert,
      message,
      submitBlocked,
      alertRef,
      status,
      formLoaded,
      resendIsLoading,
      showForm,
      signatureBlocked,
      onSubmit,
      getZipcode,
      launchSignature,
      tokenValid,
      getSignatureFile,
      onInvalidSubmit,
      resendEmailSignature,
      textInputOptions,
    }
  },
}
</script>

<style>
.is-invalid {
  border-color: #dc3545;
}
.text-gras {
  font-size: 0.8rem;
  font-weight: 900;
}
</style>
